<template>
    <div class="container-fluid mt-5 pt-5">
        <div class="row d-flex justify-content-center align-items-center mt-5">

            <img class="a" src="/assets/logo.png">

            <div class="col-8 col-sm-6 " v-if="this.telegram_id === undefined">

                <p class="text-center mt-4">La registrazione richiede di aver già installato Telegram sul proprio dispositivo. Per poter accedere al sito è necessario ottenere le credenziali tramite il bot telegram, cliccando su <a href="https://telegram.me/AltivoliBot">@AltivoliBot</a> (o cercandolo dall'app Telegram).</p>
                <hr>
                <h2>Login</h2>

                <div class="mb-3">
                    <label for="InputTelegramId" class="form-label">Telegram ID</label>
                    <input type="number" class="form-control" id="Input" aria-describedby="telegramIdlHelp"
                        v-model="user_id">
                    <div id="telegramIdlHelp" class="form-text"> <i>Non condividere questi dati con nessuno!</i></div>
                </div>
                <div class="mb-3">
                    <label for="InputPassword" class="form-label">Password</label>
                    <input type="password" class="form-control" id="InputPassword" v-model="pwd">
                    <div id="telegramPwdlHelp" class="form-text"> <i>In caso di password dimenticata usare il bot Telegram per reimpostarla</i></div>
                </div>

                <input type="checkbox" class="mb-3" v-model="remember_login"> Ricorda le credenziali <br>

                <button @click="login" class="btn btn-primary mb-2"
                    :disabled="user_id === undefined || pwd === undefined">Accedi</button>
                <p id="loginWarning"></p>
            </div>
            <div class="row" v-else>
                <div class="col">
                    <h2>Benvenuto {{this.user_data.name}} {{this.user_data.surname}}</h2>
                    <p>Per visualizzare o modificare le informazioni del tuo profilo clicca in alto a destra!</p>
                </div>
            </div>

        </div>

    </div>
</template>

<style scoped>
.a {
    width: 300px;
}
</style>

<script>
export default {
    name: 'LoginPage',

    data() {
        return {
            remember_login: false,
            user_id: undefined,
            pwd: '',
            user_data: {}
        }
    },

    props: {
        telegram_id: Number,
    },

    methods: {
        async login() {
            document.getElementById('loginWarning').innerHTML = "";
            try {
                let response = await this.$axios.post('/login', {
                    id: this.user_id,
                    password: this.pwd
                });

                this.user_data = response.data.data;
                localStorage.setItem('token', response.headers['authorization'].split(' ')[1]);

                if (this.remember_login) localStorage.setItem('user_id', this.user_id);
                else localStorage.removeItem('user_id');

                localStorage.setItem('user_data', JSON.stringify(this.user_data));
                this.$emit('emitLogin', this.user_id, this.user_data.admin);

            } catch (error) {
                console.log(error);
                document.getElementById('loginWarning').style.color = "red";
                if (error.code == "ERR_NETWORK") {
                    document.getElementById('loginWarning').innerHTML = "Errore di connessione";
                    return;
                }
                
                if (error.response.status == 404 || error.response.status == 500) {
                    document.getElementById('loginWarning').innerHTML = "Questo utente non esiste. E' necessario registrarsi tramite il bot telegram @AltivoliBot";
                    return;
                }

                if (error.response.status == 401) {
                    document.getElementById('loginWarning').innerHTML = "Credenziali errate. Per resettare la password utilizzare il bot telegram @AltivoliBot";
                    return;
                }
            }
        }
    }
}
</script>