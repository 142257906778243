import { createApp } from 'vue'
import { RouterView } from 'vue-router'
import App from './App.vue'
import router from './router'
import axios from './services/axios.js';
import NavbarTop from './components/NavbarTop'

const app = createApp(App)
app.config.globalProperties.$axios = axios;

app.component("NavbarTop",NavbarTop)

app.use(router)
app.use(RouterView)
app.mount('#app')


