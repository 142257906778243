<template>
  <div class="container-fluid mt-5 pt-5">
    <div class="row p-3">
      <blockquote class="alert alert-warning mt-5"><i class="fa-solid fa-triangle-exclamation"
          style="color:#ffd43b"></i>
        Le zone di <strong>decollo e atterraggio sono di Proprietà Privata</strong>, pertanto i piloti che usufruiscono
        dell'area sono
        pregati di rispettarla e segnalare tempestivamente ai referenti dell'<strong>ASD ALTIVOLI</strong> eventuali
        anomalie e/o danneggiamenti.
        Il sito di volo è aperto a tutti i piloti di Parapendio e Deltaplano muniti di <strong>attestato in corso di
          validità e
          copertura assicurativa</strong> prevista dai termini di Legge attualmente in vigore. Ogni pilota che decide di
        decollare
        da
        questo sito di volo, dichiara tacitamente di aver letto e compreso quanto di seguito indicato e di impegnarsi a
        rispettarlo. <br>
        <strong>Per il volo lungo il pendio, si richiamano le regole generali di precedenza,</strong> riservata a chi ha
        il costone alla
        propria destra, a chi per primo gira in termica, ai biposto, etc.
        Considerate le ridotte dimensioni del costone davanti al decollo, <strong>si prega di evitare di decollare nel
          caso di
          sovraffollamento</strong> e quindi di pericolo.
      </blockquote>
    </div>

    <div class="row p-3">
      <h2>Top Landing</h2>
      <blockquote class="alert alert-danger"><i class="fa-solid fa-triangle-exclamation" style="color:red"></i>
        <strong> Il top-landing è riservato a piloti esperti in grado di valutare tutte le variabili
          che possono presentarsi durante la manovra </strong><i class="fa-solid fa-triangle-exclamation"
          style="color:red"></i>
      </blockquote>
      <p>Il top landing, <strong>di moderata difficoltà, è consentito a tutti i piloti con adeguata esperienza e
          capacità</strong>. Si
        raccomanda particolare attenzione alla direzione ed all' intensità del vento che può risultare mediamente
        sostenuto e rafficato.
        Generalmente, con venti da quadranti sudoccidentali, si può entrare da sud, per poi rallentare la vela e
        atterrare sul piazzale o sul pendio.
        <br><u>Raccomandazioni</u>: per effettuare il top-landing sul piazzale, porre attenzione ad entrare con quota
        sufficiente
        e a non rimanere troppo indietro, evitando quindi di
        arretrare posteriormente alla zona di cemento.
        Il top landing sul pendio può invece portare a restituzioni di quota da dover gestire.
        I piloti in volo che si apprestano al top-landing, hanno sempre la precedenza su chi decolla, allo stesso modo i
        piloti in volo devono evitare di affollare il costone e permettere il decollo
      </p>
    </div>

    <div class="row p-3">
      <h2>Ripiegamento</h2>
      <p><strong>Le operazioni di ripiegamento vele</strong> (smontaggio per i Delta)<strong> non devono ostacolare i
          piloti</strong> che si accingono ad
        effettuare il top landing, sia sul pendio che sul piazzale.
      </p>
    </div>

    <div class="row p-3">
      <h2>Rispetta l'ambiente</h2>
      <p><strong>Impegniamoci tutti a mantenere pulito il nostro sito di volo.</strong> Non lasciamo cartacce, bottiglie, o rifiuti di qualunque genere. L'immondizia deve essere portata via da chi la produce in quanto questa area non è attrezzata con nessun tipo di contenitori per la raccolta della spazzatura.
      </p>
    </div>



    <div class="row justify-content-left ms-2 pl-5 mt-5">
      <h1>Decolli</h1>
    </div>
    <div class="row p-2 m-2 justify-content-left">

      <h2>Decollo Ufficiale di Monte Ripoli</h2>
      <iframe scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14730.434581322084!2d12.79755360734559!3d41.94172612827357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f82786a8f6207%3A0xd2bda3be6d43abf1!2sTandem%20Paragliding%20Take%20Off!5e1!3m2!1sit!2sit!4v1727437109026!5m2!1sit!2sit"
        style="border:0" width="310" height="350" frameborder="1"></iframe>

      <br>
      <span>
        <br>Il primo e principale si trova a Monteripoli, nel comune di Tivoli, dotato di un panorama mozzafiato con
        vista su tutta la città di Roma. Nelle giornate limpide lo sguardo può spaziare fino a vedere il riflesso del
        mare di Ostia. Per raggiungere il decollo arrivare a Tivoli, prendere quindi via Cassiano e proseguire sulla SP
        53a, dopo circa 1 km, girare a sinistra per la strada che conduce al ristorante Planet Ripoli, il quale andrà
        superato sino a giungere nel piazzale antistante il decollo.
      </span>
    </div>
    <hr>
    <div class="row p-2 m-2 justify-content-left">
      <h2>Decollo di Monte Gennaro</h2>
      <iframe scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2311.185035205064!2d12.829517!3d42.037699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDLCsDAyJzE1LjciTiAxMsKwNDknNDYuMyJF!5e1!3m2!1sit!2sus!4v1441279916239"
        style="border:0" width="310" height="350" frameborder="0"></iframe>
      <br>
      <span>
        <br>Il secondo decollo è situato nel comune di San Polo, ma si affaccia sul paese di Marcellina. Il volo è
        prettamente di alta quota sviluppandosi sul costone del Monte Gennaro con la vetta che sfiora i 1300 mt sul
        livello del mare. Per raggiungere il decollo bisogna passare nel paese di Marcellina e salire sulla strada che
        si arrampica lungo le pendici del Monte Morra, proseguire fino alla fine della strada in località Prato Favale,
        ove parcheggiare per poi fare un piccolo sentiero sterrato a piedi.
      </span>
    </div>

    <hr>

    <div class="row justify-content-left pl-5 ms-2">
      <h1>Atterraggi</h1>
    </div>
    <div class="row justify-content-center pl-5 pr-5">
      <img class="img-fluid" src="/assets/planimetria.png" style="border-radius: 3cm;">
    </div>

    <div class="row p-2 mt-3 pt-0">
      <span>
        Nell'immagine sovrastante sono evidenziati gli atterraggi utilizzabili.
        Con venti di direzione diversa da quelli indicati per il decollo, si possono avere concrete difficoltà a
        raggiungere le zone di atterraggio per la probabile presenza di discendenze da sottovento.
        <ol>
          <li>
            <strong>ATTERRAGGIO OVEST “EX ufficiale”</strong>: Non disponiamo più di un'autorizzazione ufficiale per l'utilizzo di
            quest'area. Porre attenzione alla rete che separa l'atterraggio dall'area attigua a Est. Non presente manica
            a vento. Efficienza calcolata = 5,0
          </li>
          <li>
          <strong>ATTERRAGGIO di emergenza “CAVA”</strong>: da non considerare come atterraggio in quanto insidioso per presenza di
            sottovento e rotori, specialmente nelle ore di massima attività termica o vento sostenuto. I piloti che
            volessero atterrarci ugualmente, considerino la presenza di cavi elettrici a nord, una recinzione a sud, il
            suolo duro. Presente manica a vento.
          </li>
        </ol>

      </span>
    </div>
    <div class="row p-2 m-2 justify-content-left">
      <h2>Atterraggio Ufficiale di Monte Ripoli</h2>
      <iframe frameborder="0" height="350" scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2007.9428849778099!2d12.784926048104172!3d41.942161719418145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f7892bef9efc9%3A0xe3b851014e8fb0ed!2sParagliding%20Landing%20club%20Altivoli!5e1!3m2!1sit!2sit!4v1727434090154!5m2!1sit!2sit"
        style="border:0" width="310"></iframe>
      <br>
      
      <span>
        <br>
        L'atterraggio ufficiale del volo da Monteripoli si trova sulla sinistra del decollo. Esso è a una distanza
        utile
        per essere raggiunto in efficienza a patto che non si indugi troppo nell'abbandonare il costone, altrimenti
        sarà
        difficile da raggiungere.
        <br>
        Esistono altri atterraggi non ufficiali (come 3 e 4 nella figura sovrastante), ma la zona essendo abbastanza
        impervia e ricca di vegetazione non consente di lasciare al caso la scelta della situazione più idonea
        all'esperienza del pilota. I piloti più esperti non avranno sicuramente difficoltà ad atterrare nella zona di
        decollo visto l'ampio spazio a disposizione.
      </span>
    </div>
    <div class="row p-2 m-2 justify-content-left">
      <h2>Atterraggio di Monte Gennaro</h2>
      <iframe frameborder="0" height="350" scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2311.276863158421!2d12.785012!3d42.035174!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDLCsDAyJzA2LjYiTiAxMsKwNDcnMDYuMCJF!5e1!3m2!1sit!2sus!4v1441280149769"
        style="border:0" width="310"></iframe>
      <br>
      
      <span>
        <br>
        Situato sotto le pendici del Monte Gennaro, ma non nelle immediate vicinanze del decollo, è comunque
        raggiungibile in efficienza, ma anche in questo caso non si deve indugiare troppo nel puntarlo in modo deciso
        in
        caso di perdita di quota.
      </span>
    </div>
  </div>


</template>
