<template>
  <div class="container-fluid m-0 p-0 mt-5 pt-5">
    <div class="row m-2 p-2">
      <h1 class="text-center">Meteo Station Alti<a style="color: red;">Voli</a></h1>
    </div>

    <hr>

    <div class="row m-2 p-2 justify-content-center text-center">
      Nel pannello sottostante potete controllare la webcam del decollo di Monte Ripoli e verificare i dati acquisiti dal sint con l'apposita interfaccia
      <br>
      Per ottenere l'immagine più recente della webcam ricaricare la pagina
      <br>
      
    </div>
    <div class="row m-2 p-2 justify-content-center text-center">
      <p>In alternativa è possibile chiamare l'assistente elettronico per le condizioni in decollo al <a class="ms-2" href="tel:+393883571466">+393883571466</a></p>
    </div>
    <hr>
    <div class="row justify-content-center" v-if="false">
      <div class="col-12 col-lg-6 mb-5 p-4">
        <img class="img-fluid" src="https://www.altivoli.it/sintwind/wx/weewx/wc.jpg">
      </div>

      <div class="col-12 col-lg-6">
        <iframe frameborder="0" height="570" scrolling="yes" src="https://www.altivoli.it/sintwind/wx/weewx/" width="400"></iframe>
      </div>
     
    </div>
    <div class="row justify-content-center text-center" v-else>
      <p>Stiamo lavorando per riportare il vecchio bot in funzione! </p>
    </div>
  </div>
</template>