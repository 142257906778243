<template>
  <div class="container-fluid mt-5 pt-5">

    <div class="row mt-2">
      <div class="col text-center">
        <h1>Flyzone Tivoli-Lucretili</h1>
      </div>
    </div>

    <hr>

    <div class="row align-items-center">
      <div class="col-12 col-lg-6">
        <img class="img-fluid" src="/assets/map2.bmp" alt="">
      </div>

      <div class="col-12 col-lg-6 text-center align-items-center d-flex flex-column">
        <span class="mb-3 mt-3">
          Nell'immagine sono evidenziate le aree che rientrano nell'ATZ dell'Aeroporto Militare
          di Guidonia e le altre zone di volo regolamentate dal NOTAM pubblicato in AIP del 31 gennaio 2019, in
          riferimento al quale tutti i piloti registrandosi sul sito www.altivoli.it avranno accesso alle informazioni
          riguardo l'accettazione di quanto riportato nella Lettera di Accordo, sottoscritta con il 60° Stormo
          dell'Aeronautica Militare di Guidonia e nella Lettera di Operazioni sottoscritta con ENAV ACC di Roma; con
          obbligo di rispetto integrale delle stesse.
          Ogni volta che si prevede di volare nell'area Tivoli/Monte Gennaro, tutti i piloti devono comunicare la
          propria intenzione notificandosi sul gruppo WhatsApp dedicato “FlyZone Tivoli-Lucretili” i cui dettagli di
          iscrizione sono integralmente riportati nel nostro sito web.

        </span>
        <span>
          Per poter volare nella zona indicata nella mappa è necessario essersi <a href="/login">iscritti</a> al sito ed
          aver accettato l'accordo con il 60° stormo. <br> <strong>ATTENZIONE:</strong> <u>chiunque non seguirà la
            procedurà verrà segnalato alle autorità competenti</u>
        </span>
      </div>
    </div>

    <hr>

  </div>
</template>
