<template>
  <div class="containter-fluid mt-0 p-0">
    <div class="row p-0">
      <div class="col p-0">

        <nav class="navbar navbar-expand-lg sticky-top">
          <div class="container-fluid">
            <!-- Logo -->
            <a class="navbar-brand" href="#" @click="goToHome">
              <img src="/assets/logo2.png" alt="Logo Altivoli" width="115">
            </a>

            <!-- Toggler Button for Mobile -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Navbar Links and Form Section -->
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-bottom">
                <!-- Navigation Links -->

                <li class="nav-item dropdown">
                  <a :class="'nav-link dropdown-toggle ' + isActive(0)" aria-current="page" href="#" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">Il Club</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/home#chisiamo">Chi Siamo</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="/home#biposti">Biposti</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="/home#meteodecollo">Meteo Decollo</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="/home#direttivo">Direttivo</a></li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link ' + isActive(1)" @click="goToEvents">Eventi</a>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link ' + isActive(2)" @click="goToFlyzone">Flyzone</a>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link ' + isActive(3)" @click="goToLocations">Locations</a>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link ' + isActive(4)" @click="goToMeteo">Meteo Station</a>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link ' + isActive(5)" @click="goToContacts">Contatti</a>
                </li>
              </ul>

              <!-- User Login, Logout or Account Icon -->
              <div>
                <!-- Logged in icon -->
                <i class="fa-solid fa-user hoverer" @click="goToUserPanel" v-if="logged"
                  style="color: rgba(255,0,0,1)"></i>
                <!-- Spacing between icons -->
                <span style="display: inline-block; width: 20px;"></span>
                <!-- Login Button (if not logged) -->
                <button class="btn me-2 " @click="goToLogin"
                  v-if="!logged">Accedi/Registrati</button>
                <!-- Logout Icon (if logged in) -->
                <i class="fa-solid fa-right-to-bracket hoverer" @click="logout" v-else></i>
              </div>
            </div>
          </div>
        </nav>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    logged: Boolean,
  },

  computed: {
    isActive() { return (section) => (this.page === section ? 'active' : ''); },
  },

  methods: {
    logout() { this.$emit("emitLogout"); },
    goToHome() { this.$emit("emitPageChange", { page: 'home' }); },
    goToEvents() { this.$emit("emitPageChange", { page: 'events' }); },
    goToFlyzone() { this.$emit("emitPageChange", { page: 'flyzone' }); },
    goToContacts() { this.$emit("emitPageChange", { page: 'contacts' }); },
    goToLogin() { this.$emit("emitPageChange", { page: 'login' }); },
    goToUserPanel() { this.$emit("emitPageChange", { page: 'manage' }); },
    goToMeteo() { this.$emit("emitPageChange", { page: 'meteo' }); },
    goToLocations() { this.$emit("emitPageChange", { page: 'locations' }); },
  }
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .nav-link {
    margin-left: 10px;
    background-color: rgba(255, 255, 255, 0);
    font-weight: bold;
    color: white !important;
  }

  .dropdown-item {
    border: 2px solid rgba(0, 0, 0, 0.432);
    font-weight: bold;
  }

  .dropdown-menu {
    border: 0px solid red ;
  }

  .navbar-collapse{
    border-radius: 5px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .btn{
    color: white !important;
  }

  .dropdown-item{
    color: white !important;
  }

}


.navbar {
  background-color: transparent;
  position: absolute;
  width: 100%;
}

.dropdown-menu {
  background-color: transparent;
}

.nav-link {
  color: black;
  font-family: 'Orbitron';
}

.dropdown-item {
  color: black;
  font-family: 'Orbitron';
}

.btn{
  background-color: rgba(255, 255, 255, 0.35); 
  font-weight: bold;
  color: black;
  font-family: 'Orbitron';
}

.nav-item:hover {
  cursor: pointer;
}

.nav-link.active{
  color: red !important;
  font-weight: bold;
}

.hoverer {
  cursor: pointer;
}
</style>