<template>
  <div class="container-fluid m-0 p-0">
    <div class="row p-0 m-0">
      <div class="col p-0 m-0">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
          <div class="carousel-inner">

            <!-- Overlay and Writing (Applied to all slides) -->
            <div class="carousel-overlay"></div>
            <div class="carousel-writing d-flex align-items-center">
              <div class="row col p-0 m-0">
                <h1 class="yellowtail">club AltiVoli</h1>
              </div>
              <div class="row p-0 m-0 col-10">
                <p class="montserrat">“Al decollo di Monteripoli si vedono dei tramonti favolosi. Io volo qui”</p>
                <p class="text-center montserrat">cit. Sante Salvati</p>
              </div>
            </div>

            <!-- Carousel Items -->
            <div class="carousel-item active">
              <img class="d-block w-100" src="/assets/5.jpg" alt="First slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 img-fluid" src="/assets/4.jpg" alt="Second slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 img-fluid" src="/assets/3.jpeg" alt="Third slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 img-fluid" src="/assets/2.jpg" alt="Fourth slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 img-fluid" src="/assets/1.jpg" alt="Fifth slide">
            </div>
          </div>

          <!-- Carousel Controls -->
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
            data-bs-slide="prev">

            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
            data-bs-slide="next">

            <span class="visually-hidden">Next</span>
          </button>
        </div>

      </div>
    </div>

    <hr>

    <div class="row m-0 p-3">
      <div class="col">
        <div class="container-fluid">
          <div class="row text-left">
            <h2>Benvenuti!</h2>
          </div>
          <div class="row">
            <div class="col-12 col-lg-7">
              A Tivoli, alle porte di Roma, più precisamente a Monteripoli, coordinate: 41.941588, 12.806869, il club
              locale, l'ASD Altivoli - Volo Libero Tivoli,
              gestisce il sito di volo e promuove il volo libero in tutte le sue forme. <br><br> Recentemente il decollo
              è stato ancora
              ampliato ed è possibile, ancora più che nel passato, effettuare il top landing, ossia atterrare nello
              stesso
              punto da cui si è decollati, magari dopo aver volato per ore con un panorama che spazia dai resti di Villa
              Adriana al Monte Gennaro, senza trascurare Roma e fino a riconoscere nitidamente il mare.<br><br> Chiunque
              volesse,
              potrà trascorrere un pomeriggio anche semplicemente osservando i piloti che decollano, fanno quota e
              magari
              si spostano anche per parecchie decine di chilometri, magari tornando alcune ore dopo con un'espressione
              sul
              viso unica e che, evidentemente anche il grande Leonardo da Vinci conosceva, visto che diceva:<br> “Una
              volta che
              abbiate conosciuto il volo, camminerete sulla terra guardando il cielo, perché là siete stati e là
              desidererete tornare”. <br><br>E tu, cosa aspetti?
            </div>
            <div class="col-12 col-lg-5">
              <img class="img-fluid rounded-circle" src="/assets/para.jpg" alt="">
            </div>
          </div>
        </div>
      </div>

    </div>

    <hr>

    <div class="row p-0 m-0">
      <div class="col">
        <div class="container-fluid">
          <div class="row text-left" id="chisiamo">
            <h2>Chi Siamo</h2>
          </div>
          <div class="row p-3">
            <p>Siamo un gruppo di amici, appassionati di volo libero che hanno le proprie “radici” nel sito di volo di
              Monteripoli (Tivoli) e del Monte Gennaro (San Polo dei Cavalieri/Marcellina). Proveniamo in gran parte
              dalla fusione di due club locali che hanno deciso di proseguire la loro attività in maniera congiunta,
              avendo come comune denominatore la passione per il volo e il desiderio di tenere interessi economici o
              strumentalizzazioni, fuori dalla nostra realtà.
              I nostri obiettivi sono:</p>
            <ul>
              <li>Promuovere il volo libero e i nostri siti di volo</li>
              <li>Organizzare eventi</li>
              <li>Estendere le nostre esperienze in siti anche diversi da quelli ove voliamo abitualmente.</li>
            </ul>
          </div>
        </div>
      </div>

    </div>

    <hr>

    <div class="row p-0 m-0">
      <div class="col">
        <div class="container-fluid">
          <div class="row text-left" id="biposti">
            <h2>Biposti</h2>
          </div>

          <div class="row p-2">
            <blockquote class="alert alert-warning"><i class="fa-solid fa-triangle-exclamation"
                style="color:#ffd43b"></i>
              Chi volesse provare l'emozione del volo, potrà rivolgersi ad un pilota abilitato o istruttore, tra quelli
              di seguito riportati.
              Si precisa che l'ASD Altivoli non entra nel merito degli accordi economici tra pilota/istruttore e
              passeggero, né nello svolgimento dell'attività di volo.
            </blockquote>
          </div>

          <div class="row p-2">
            <p><strong>Avete mai desiderato di volare?</strong> Siete nel posto giusto perché alcuni dei nostri soci
              sono dei piloti
              certificati dall'Aeroclub d'Italia per poter trasportare passeggeri! In seguito potete trovare i loro
              contatti:
            </p>
            <p class="text-center"><strong><i>Alessandro Palma</i></strong>: <a href="tel:+393382223172">3382223172</a>
            </p>
            <p class="text-center"><strong><i>Giancarlo Saraceni</i></strong>: <a
                href="tel:+393338236081">3338236081</a></p>
            <p class="text-center"><strong><i>Tony Ruta</i></strong>: <a href="tel:+393288910440">3288910440</a></p>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row p-0 m-0">
      <div class="col-12 d-flex justify-content-center" id="meteodecollo">
        <h2>Com' è in decollo?</h2>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <p>Puoi consultare la nostra stazione meteorologica disponibile 24/7 <button
            @click="$emit('emitPageChange', { page: 'meteo' })"
            style="background:none;border:none;padding:0;color:blue;text-decoration:underline;cursor:pointer;">qui</button>.
          In aggiunta puoi dare un'occhiata tramite i widget sottostanti!</p>
      </div>

    </div>

    <div class="row m-0 p-0">
      <div class="col-12 col-lg-6 justify-content-end d-flex">
        <iframe frameborder="0" height="302" scrolling="no"
          src="https://www.3bmeteo.com/moduli_esterni/localita_6_giorni_compatto/7267/ffffff/800000/5e5e5e/ffffff/it"
          width="187"></iframe>
      </div>
      <div class="col-12 col-lg-6 justify-content-start d-flex">
        <iframe frameborder="0" height="300"
          src="https://embed.windyty.com/?surface,wind,now,42.196,12.832,6,,menu,message," width="200"></iframe>
      </div>
    </div>

    <hr>
    <div class="row m-0 p-0 justify-content-center">
      <div class="col-12 col-lg-5">
        <img class="img-fluid rounded-circle" src="/assets/sand.jpg" height="140">
      </div>
      <div class="col-12 col-lg-7 text-center" id="direttivo">
        <h2>Il Direttivo</h2>
        <p style="font-family: 'Orbiter';">Di seguito i membri del consigio direttivo della ASD Altivoli : <br><br>
          Terenzi Franco <strong>PRESIDENTE</strong><br>
          Pietro Vitale e Roberto Benvenuti <strong>VICEPRESIDENTI</strong><br>
          Alberto Santarelli <strong>SEGRETARIO TESORIERE</strong><br>
          Michele Morabito<br>
          Enea Belli<br>
          Tony Ruta<br>
          Mariano Iacovelli<br>
        </p>
      </div>
    </div>

    <hr>

  </div>
</template>

<style scoped>
@media (max-width: 576px) {

  .carousel-writing {
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }
}

.yellowtail {
  font-family: "Yellowtail", sans-serif;
  text-shadow: 0px 0px 10px #000000;
  font-weight: 900;
  font-size: 74px;
}

.lora {
  font-family: 'Lora', serif;
  text-shadow: 0px 0px 10px #000000;
  font-size: 21px;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
  text-shadow: 2px 3px 6px #000000;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 2px;
}

.carousel-inner {
  position: relative;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(190, 107, 13, 0.288);
  /*190, 108, 13, 0.432*/
  /* Semi-transparent yellow */
  z-index: 2;
  /* Ensure it appears above the images but below the text */
}

.carousel-writing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 3;
}

/* Ensuring the carousel items are relative to position the overlay */
.carousel-item {
  position: relative;
}

/* Ensuring carousel images fill the container */
.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  /* Adjust based on your design */
}
</style>