<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <NavbarTop :logged="telegram_id == undefined ? false : true" :page="page" @emitPageChange="catchPageChange"
          @emitLogout="logout" />
      </div>
    </div>
    <div class="row ">
      <div class="col m-0 p-0">
        <RouterView @emitPageChange="catchPageChange" @emitLogout="logout" @emitLogin="login"
          :telegram_id="this.telegram_id" :admin="this.admin" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="container">
          <footer class="py-3 my-4">

            <p class="text-center text-body-secondary">© 2024 Developed and Designed by <a
                href="https://telegram.me/flyingmp">Michele Palma</a></p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      page: 0,
      telegram_id: undefined,
      admin: false
    }
  },
  mounted() {
    let cp = localStorage.getItem('page');
    if (cp !== null) this.page = parseInt(cp);
    else localStorage.setItem('page', this.page);

    let user = localStorage.getItem('user_id');
    if (user !== null) this.telegram_id = parseInt(user);

    let admin = localStorage.getItem('user_data');
    if (admin !== null) this.admin = JSON.parse(admin).admin;
  },
  beforeUnmount() { localStorage.setItem('page', 'home'); },

  methods: {
    catchPageChange(pageObj) {
      const possible_values = ['home', 'events', 'flyzone', 'locations', 'meteo', 'contacts', 'login', 'manage'];
      let value = pageObj.page
      if (!possible_values.includes(value)) return;
      this.$router.push(`/${value}`);
      this.page = possible_values.indexOf(value);
      localStorage.setItem('page', this.page);

    },

    login(tg_id, admin) {
      this.telegram_id = tg_id;
      this.admin = admin;
      this.catchPageChange({ page: 'manage' });
    },

    logout() {
      this.telegram_id = undefined;
      localStorage.removeItem('user_id');
      localStorage.removeItem('token')
      this.catchPageChange({ page: 'home' });
    }
  },
}
</script>
