import {
    createRouter,
    createWebHistory,
} from 'vue-router'

import HomePage from '../views/HomePage'
import ContactUsPage from '../views/ContactUsPage'
import EventsPage from '../views/EventsPage.vue'
import LoginPage from '../views/LoginPage.vue'
import FlyzonePage from '@/views/FlyzonePage.vue'
import UserPage from '../views/UserPage.vue'
import MeteoPage from '../views/MeteoPage.vue'
import GPSPage from '@/views/GPSPage.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: HomePage
        },
        {
            path: '/login',
            component: LoginPage
        },
        {
            path: '/events',
            component: EventsPage
        },
        {
            path: '/contacts',
            component: ContactUsPage
        },
        {
            path: '/flyzone',
            component: FlyzonePage
        },
        {
            path: '/manage',
            component: UserPage
        },
        {
            path: '/meteo',
            component: MeteoPage
        },
        {
            path: '/locations',
            component: GPSPage
        },
        {
            path: "/:catchAll(.*)",
            component: HomePage
        },
    ]
})

export default router